import React from "react"

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';

import "./css/SocialMedia.css";

const SocialMedia = () => {
    return (
        <div className='social-media'>
            <a className='social-link' href='https://www.facebook.com/herzschlag.hemmingen/' target='_blank'><FacebookIcon className='social-icon'  /></a>
            <a className='social-link' href='https://www.instagram.com/herzschlag.hemmingen/?hl=de/' target='_blank'><InstagramIcon className='social-icon'  /></a>
            {/*<a className='social-link' href='https://www.youtube.com/channel/test' target='_blank'><YouTubeIcon className='social-icon' /></a>*/}
        </div>
    );
};

export default SocialMedia;