/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "./css/Layout.css"
import Header from "./Header"
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import Footer from "./Footer";
import { Helmet } from "react-helmet"

const offset = "75";

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {
        offset: offset
    });
}

const Layout = (props) => {
    return (
        <div className="layout">
            <Helmet>
                <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet"/>
            </Helmet>
            <Header />
            <div style={{marginTop: `${offset}px`}}>{props.children}</div>
            <Footer/>
        </div>
    )
};
export default Layout;
