export const isValidUrl = (string) => {
    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;
    }
};

/**
 *
 * Construct url
 *
 * @param url
 * @returns {string}
 */
export function constructUrl(url) {
    let pathname = url || '/404';

    if(isValidUrl(url)) {
        pathname = new URL(url).pathname.substring(0);
    }
    return pathname;
}