import React from "react"
import { Link } from "gatsby"
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {constructUrl} from "../utils";

export function Menu({name, menus}) {
    return menus.map(edge => {
        if (edge.node.name === name) {
            return edge.node.items.map((item, index) => {
                return <Link to={constructUrl(item.url)} className="drawer-link" key={item.slug + "_" + index}>
                    <ListItem button>
                        <ListItemText primary={item.title}/>
                    </ListItem>
                </Link>
            });
        }
        return null;
    });
}